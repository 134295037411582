<template>
    <div class="base-confirm-container">
        <div class="confirm-wrapper">
            <div class="confrim-title">{{params.title}}</div>
            <div class="confrim-tip">{{params.content}}</div>
            <div class="confrim-button-container">
                <div class="confirm-cancel-button" @click="cancel">取消</div>
                <div class="confirm-confirm-button"  @click="confirm">确定</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
   
  props:['params'],
    methods: {
        confirm() {
            this.$emit("close",true);
        },
        cancel(){
            this.$emit("close",null);
        }
    },
};
</script>
<style lang="scss" scoped>
.base-confirm-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .confirm-wrapper {
        background: #ffffff;
        border-radius: 20px;
        overflow: hidden;
        width: 250px;
        .confrim-title {
            text-align: center;
            font-size: 18px;
            padding-top: 20px;
            padding-bottom: 15px;
        }
        .confrim-tip {
            text-align: center;
            font-size: 16px;
            padding-bottom: 10px;
            line-height: 24px;
            padding: 0 10px;
            padding-bottom: 10px;
        }
        .confrim-button-container {
            border-top: 1px solid #dddddd;
            display: flex;
            .confirm-confirm-button,
            .confirm-cancel-button {
                flex: 1;
                height: 47px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .confirm-cancel-button {
                border-right: 1px solid #dddddd;
            }
            .confirm-confirm-button {
                background: #f54f56;
                color: #ffffff;
            }
        }
    }
}
</style>