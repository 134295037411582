<template>
    <div class="my-project-container">
        <div class="project-page-title"> 作品管理</div>
        <div class="project-page-sub-title">我的作品（{{total}}）</div>
        <div class="project-main-container noscroll">
            <div class="project-item-container" v-for="item in list" :key="item.id" @click="onProjectOperate(item)">
                <div class="project-cover-container">
                    <img class="project-cover" :src="item.cover">
                </div>
                <div class="project-info-container">
                    <div class="project-title">{{item.name}}</div>
                    <div class="project-time">{{item.createTime}}</div>
                </div>
                <div class="project-operator-container" >
                    <i class="iconfont icon-more"></i>
                </div>
            </div>
            <div style="height:60px"></div>
        </div>
       <CreateProject @create="onProjectCreate"></CreateProject>
        <transition name="slide-bottom">
            <Material v-if="showMaterial" @close="closeMaterial"></Material>
        </transition> 
        <OperateProject v-if="operateProject" :project="operateProject" @close="clearOperateProject"></OperateProject>
        
    </div>
</template>
<script>
import { myProjectPage } from "api/pano/pano";
import OperateProject from "./components/operate-project";
import CreateProject from "./components/create-project";
import Material from "./components/material";
export default {
    components: {
        OperateProject,
        CreateProject,
        Material,
    },
    mounted() {
        myProjectPage({ pageIndex: 1, pageSize: 100, name: null }).then((o) => {
            this.total = o.total;
            this.list = o.data;
        });
    },
    data() {
        return {
            operateProject: null,
            showMaterial: false,
            total: 0,
            list: [],
        };
    },
    methods: {
        clearOperateProject() {
            this.operateProject = null;
        },
        onProjectOperate(project) {
            this.operateProject = {...project};
        },
        onProjectCreate() {
            this.showMaterial = true;
        },
        closeMaterial() {
            this.showMaterial = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.my-project-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    background: #f7f7f8;
    .project-page-title {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
    }
    .project-page-sub-title {
        height: 30px;
        padding-left: 20px;
        background: #e7e7e7;
        display: flex;
        align-items: center;
    }
    .project-main-container {
        padding: 20px;
        height: calc(100vh - 110px);
        overflow-y: scroll;
        .project-item-container {
            padding: 10px 0;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            .project-cover-container {
                width: 80px;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 20px;
                .project-cover {
                    width: 80px;
                    height: 80px;
                }
            }
            .project-info-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .project-title {
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: 600;
                }
                .project-time {
                    line-height: 18px;
                    font-size: 12px;
                    color: #999999;
                }
            }
            .project-operator-container {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 5px;
            }
        }
    }
}
</style>