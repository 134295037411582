<template>
    <div class="create-project-container" @click="onProjectCreate">
        <i class="iconfont icon-hao"></i>
    </div>
</template>
<script>
export default {
    methods:{
        onProjectCreate(){
            this.$emit("create")
        }
    }
}
</script>
<style lang="scss" scoped>
    .create-project-container{
        position: absolute;
        right: 10px;
        bottom: 120px;
        width: 60px;
        height: 60px;
        background: $pano-theme;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
            background: linear-gradient(
                to right,
                $pano-theme-light,
                $pano-theme
            );
        .iconfont{
            color: $pano-font-color;
            font-size: 30px;
        }
    }
</style>