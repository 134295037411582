<template>
    <div class="material-container">
        <div class="material-title-container">
            <div class="material-close" @click="close">
                <i class="iconfont icon-close"></i>
            </div>
            <div class="material-title">选择素材图片</div>
            <div class="material-close"></div>
        </div>
        <div class="material-main-container">
            <div class="material-item-container" :class="{'material-item-container-active':isSelect(item)}" v-for="item in list" :key="item.id" @click="onSelect(item)">
                <div class="material-cover">
                    <img class="material-cover-image" :src="item.cover" alt="">
                </div>
                <div class="material-info-container">
                    <div class="material-title">{{item.name}}</div>
                    <div class="material-status">{{item.status==1?"创建中":(item.status==2?"成功":"失败")}}</div>
                    <div class="material-time">{{item.createTime}}</div>
                </div>
                <div class="material-select-container">
                    <i class="iconfont icon-dui1"></i>
                </div>
            </div>
            <div style="height:80px"></div>
        </div>
        <div class="material-confirm-container">
            <div class="material-select-tip">已选择素材 <span class="select-count">{{selects.length}}</span>/15</div>
            <div class="material-select-button" @click="onCreateProject">创建项目</div>
        </div>
        <div class="material-upload-container" @click="doUploadFile">
            <i class="iconfont icon-yunshangchuan_o"></i>
            <input type="file" ref="uploadFlie" accept="image/*" class="upload-file-input" @change="selectImg">
        </div>
        <transition name="slide-bottom">
            <UploadMaterial v-if="uploadBase64&&uploadMaterialName" :file="uploadFile" :base64="uploadBase64" :fileName="uploadMaterialName" @close="onUploadMaterialClose"></UploadMaterial>
        </transition>
    </div>
</template>
<script>
import ConfrimRightTrue from "@/components/confirm-right-true";
import { materialPage, createProject } from "api/pano/pano";
import UploadMaterial from "./components/upload-material";
export default {
    components: {
        UploadMaterial,
    },
    mounted() {
        this.requestList();
    },
    data() {
        return {
            list: [],
            total: 0,
            selects: [],
            uploadMaterialName: null,
            uploadBase64: null,
            uploadFile: null,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        onSelect(material) {
            if (material.status == 2) {
                let index = this.selects.findIndex((o) => o == material.id);
                if (index > -1) {
                    this.selects.splice(index, 1);
                } else {
                    this.selects.push(material.id);
                }
            }
        },
        isSelect(material) {
            return this.selects.some((o) => o === material.id);
        },
        selectImg(e) {
            const file = e.target;
            if (file && file.files && file.files.length) {
                this.returnUrl = null;
                this.uploadFile = file.files[0];
                this.uploadMaterialName = this.uploadFile.name;
                const reader = new FileReader();
                reader.onloadend = (r) => {
                    this.uploadBase64 = r.target.result;
                    // e.target.value = "";
                };
                reader.readAsDataURL(file.files[0]);
            }
        },
        onUploadMaterialClose(value) {
            this.uploadMaterialName = null;
            this.uploadBase64 = null;
            if (value) {
                this.requestList();
            }
        },
        doUploadFile() {
            this.$refs.uploadFlie.click();
        },
        requestList() {
            materialPage({ pageIndex: 1, pageSize: 100 }).then((o) => {
                this.total = o.total;
                this.list.splice(0, this.list.length);
                this.list = [...o.data];
            });
        },
        onCreateProject() {
            createProject({
                name: "未命名项目",
                materialIds: [...this.selects],
            }).then((o) => {
                this.selects.splice(0, this.selects.length);
                this.$modal({
                    template: ConfrimRightTrue,
                    params: {
                        title: "成功!",
                        content: "创建项目成功,马上去编辑",
                    },
                    close: (r) => {
                        if (r) {
                            window.location = `${window.origin}/webapp/edit/${o}`;
                        }
                    },
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.material-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: #f7f7f7;
    .material-upload-container {
        position: absolute;
        bottom: 120px;
        right: 10px;
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
    background: #f7f7f7;
        .iconfont {
            color: $pano-theme;
            font-size: 30px;
            font-weight: 600;
        }
        .upload-file-input {
            position: absolute;
            right: 0;
            bottom: -999px;
            opacity: 0;
        }
    }
    .material-confirm-container {
    background: #f7f7f7;
        border-top: 1px solid #eeeeee;
        box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        left: 0;
        height: 60px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .material-select-tip {
            margin: 0 20px;
            font-size: 12px;
            .select-count {
                font-weight: 600;
                font-size: 16px;
                color: $pano-theme;
            }
        }
        .material-select-button {
            margin: 0 20px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            width: 120px;
            border-radius: 20px;
            background: $pano-theme;
            color: $pano-font-color;
        }
    }
    .material-title-container {
        height: 40px;
        display: flex;
        border-bottom: 1px solid #eeeeee;
        .material-title {
            flex: 1;
            line-height: 40px;
            height: 40px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
        }
        .material-close {
            padding: 0 20px;
            line-height: 40px;
            height: 40px;
        }
    }
    .material-main-container {
        height: calc(100vh - 40px);
        overflow-y: scroll;
        padding: 0 20px;
        .material-item-container {
            display: flex;
            border-bottom: 1px solid #eeeeee;
            padding: 10px 0;
            position: relative;
            .material-select-container {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999999;
                border: 1px solid #999999;
                box-sizing: border-box;
                border-radius: 50%;
            }
            .material-cover {
                width: 80px;
                height: 80px;
                border-radius: 8px;
                margin-right: 20px;
                overflow: hidden;
                .material-cover-image {
                    width: 80px;
                    height: 80px;
                }
            }
            .material-info-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .material-title {
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: 600;
                }
                .material-time {
                    line-height: 18px;
                    font-size: 12px;
                    color: #999999;
                }
            }
        }
        .material-item-container-active {
            .material-select-container {
                position: absolute;
                background: $pano-theme;
                color: $pano-font-color;
                border: 1px solid $pano-theme;
            }
        }
    }
}
</style>