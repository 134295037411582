<template>
    <div class="operate-project-container">
        <div class="masking-container" @click="close"></div>

        <transition name="slide-bottom">
            <div class="operate-main-container" v-show="show">
                <div class="operate-title ellipsis">作品:{{project.name}}</div>
                <div class="operate-item-container">
                    <div class="operate-item" @click="view">
                        <div class="operate-item-icon">
                            <i class="iconfont icon-shijiaoxuanzhuanduiqi"></i>
                        </div>
                        <div class="operate-item-desc">预览</div>
                    </div>
                    <div class="operate-item" @click="copyLink">
                        <div class="operate-item-icon">
                            <i class="iconfont icon-link1"></i>
                        </div>
                        <div class="operate-item-desc">复制链接</div>
                    </div>
                    <div class="operate-item" @click="edit">
                        <div class="operate-item-icon">
                            <i class="iconfont icon-edit"></i>
                        </div>
                        <div class="operate-item-desc">编辑</div>
                    </div>
                    <div class="operate-item">
                        <div class="operate-item-icon">
                            <i class="iconfont icon-shanchu1"></i>
                        </div>
                        <div class="operate-item-desc">删除</div>
                    </div>
                </div>
                <div class="operate-cancel" @click="close">取消</div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        project: {
            type: Object,
            default: {},
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.show = true;
        });
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        close() {
            this.show = false;
            setTimeout(() => {
                this.$emit("close");
            }, 500);
        },
        view() {
           window.location=`${window.origin}/webapp/pano/${this.project.id}`
        },
        edit() {
            window.location=`${window.origin}/webapp/edit/${this.project.id}`
        },
        copyLink() {
            const el = document.createElement("textarea");
            el.value = `${window.origin}/webapp/pano/${this.project.id}` + "";
            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            const selected =
                document.getSelection().rangeCount > 0
                    ? document.getSelection().getRangeAt(0)
                    : false;
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            this.$alert({ content: "链接复制成功" });
        },
    },
};
</script>
<style lang="scss" scoped>
.operate-project-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: rgba(#000000, 0.6);
    .masking-container {
        flex: 1;
    }
    .operate-main-container {
        height: 40vh;
        background: #ffffff;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .operate-title {
            border-bottom: 1px solid #eeeeee;
            line-height: 30px;
            height: 30px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            padding: 0 20px;
        }
        .operate-item-container {
            padding: 20px;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #eeeeee;
            .operate-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px;
                min-width: 60px;
                .operate-item-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    background: #eeeeee;
                    border-radius: 4px;
                    .iconfont {
                        font-size: 20px;
                        color: #999999;
                    }
                }
                .operate-item-desc {
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    font-size: 12px;
                    color: #999999;
                }
            }
        }
        .operate-cancel {
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: #999999;
            font-size: 18px;
        }
    }
}
</style>