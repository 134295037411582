<template>
    <div class="upload-material-container">
        <div class="upload-material-title-container">
            <div class="upload-close" @click="close">
                <i class="iconfont icon-close"></i>
            </div>
            <div class="upload-title">上传素材</div>
            <div class="upload-close"></div>
        </div>
        <div class="upload-material-main-container">
            <div class="upload-material-input-container">
                <pano-input placeholder="请输入素材名称" v-model="name"></pano-input>
            </div>
            <div class="upload-material-cover-container">
                <img class="upload-material-cover-img-container" :src="base64" alt="">
            </div>
            <div class="upload-material-button-container">
                <div class="upload-material-button" :class="{'upload-material-button-disable':uploading}" @click="onUpload">
                    <i class="iconfont icon-yunshangchuan_o"></i>{{uploading?"上传中...":"上传"}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { uploadLocal } from "api/common/common";
import { addMaterial } from "api/pano/pano";
import Util from "common/js/util";
import axios from 'axios';
export default {
    props: {
        base64: {
            type: String,
            default: null,
        },
        fileName: {
            type: String,
            default: null,
        },
        file:{
            type:File,
            default:null
        }
    },
    data() {
        return {
            name: "",
            uploading: false,
        };
    },
    methods: {
        close() {
            if (!this.uploading) {
                this.$emit("close");
            }
        },
        onUpload() {
            if (!this.uploading) {
                const formData = new FormData();
                const blob = Util.base64ToBlob(this.base64);
                console.log(this.file)
                formData.append("file",blob,this.fileName)

            //      axios({
            //     method: 'post',
            //     url: 'http://localhost:8107/api/common/fileServer/uploadLocal1',//跳转路径
            //     data: formData,
            //     headers: {
            //      'Content-Type': 'multipart/form-data'
            //     }
            // });
            // const config = { headers: { 'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime() } };
//             let config = {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     },
//     transformRequest: [function (data) {
//       return data
//     }]
//   }
// let fd = new FormData();
// fd.append('file',blob,this.fileName)
// return axios.post("http://localhost:8107/api/common/fileServer/uploadLocal2xxxx", fd, config)
                uploadLocal(formData)
                    .then((o) => {
                        let url = o.url;
                        addMaterial({ sourcePath: url, name: this.name })
                            .then((o) => {
                                this.$emit("close",true);
                                this.uploading = false;
                            })
                            .catch((e) => (this.uploading = false));
                    })
                    .catch((e) => (this.uploading = false));
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.upload-material-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: $pano-framework-tool-item-background;
    color: $pano-font-color;
    .upload-material-title-container {
        height: 40px;
        display: flex;
        border-bottom: 1px solid #646464;
        .upload-title {
            flex: 1;
            line-height: 40px;
            height: 40px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
        }
        .upload-close {
            padding: 0 20px;
            line-height: 40px;
            height: 40px;
        }
    }
    .upload-material-main-container {
        height: calc(100vh - 40px);
        .upload-material-input-container {
            margin: 20px auto;
            width: 80vw;
        }
        .upload-material-cover-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .upload-material-cover-img-container {
                width: 80vw;
                border-radius: 12px;
                overflow: hidden;
            }
        }
        .upload-material-button-container {
            margin-top: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            .upload-material-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80vw;
                height: 40px;
                border-radius: 30px;
                background: $pano-theme;
                font-size: 16px;
                .iconfont {
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
            .upload-material-button-disable {
                background: $pano-theme-light;
            }
        }
    }
}
</style>